// import React, { useEffect, useState } from "react";
// import { motion } from "framer-motion";

// const ImageRain = ({ imageUrl, dropCount = 50 }) => {
//   const [drops, setDrops] = useState([]);

//   useEffect(() => {
//     // Generate random drop positions across the full width of the viewport
//     const newDrops = Array.from({ length: dropCount }).map(() => ({
//       id: Math.random(),
//       delay: Math.random() * 5, // Random delay for each drop to start
//       duration: 2 + Math.random() * 3, // Random duration for falling
//       xPosition: Math.random() * 100, // Random position across the width (percentage)
//       size: 20 + Math.random() * 30, // Random size of the drop
//     }));
//     setDrops(newDrops);
//   }, [dropCount]);

//   return (
//     <div className="rain-container">
//       {drops.map((drop) => (
//         <motion.img
//           key={drop.id}
//           src={imageUrl}
//           className="rain-drop"
//           style={{
//             left: `${drop.xPosition}vw`,
//             width: `${drop.size}px`, // Random size of the image
//           }}
//           initial={{ y: "-100px", opacity: 0 }} // Start above the viewport
//           animate={{ 
//             y: "100vh", // Fall to the bottom
//             opacity: [0, 1, 0], // Fade in and then out
//           }}           transition={{
//             delay: drop.delay,
//             duration: drop.duration,
//             ease: "linear",
//             repeat: 0, // No repeating, each drop falls once
//           }}
//         />
//       ))}
//     </div>
//   );
// };

// export default ImageRain;

import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const ImageRain = ({ imageUrls = [], dropCount = 50 }) => {
  const [drops, setDrops] = useState([]);
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    // Check if imageUrls is defined and is an array
    if (!Array.isArray(imageUrls) || imageUrls.length === 0) {
      console.error("imageUrls is not defined or empty");
      return;
    }

    if (!animationStarted) {
      // Add a 200ms delay to trigger the animation
      setTimeout(() => {
        // Generate random drop positions across the full width of the viewport
        const newDrops = Array.from({ length: dropCount }).map(() => ({
          id: Math.random(),
          delay: Math.random() * 5, // Random delay for each drop to start
          duration: 2 + Math.random() * 3, // Random duration for falling
          xPosition: Math.random() * 100, // Random position across the width (percentage)
          size: 40 + Math.random() * 30, // Random size of the drop
          imageUrl: imageUrls[Math.floor(Math.random() * imageUrls.length)], // Randomly select an image URL
        }));
        setDrops(newDrops);
        setAnimationStarted(true); // Ensure the animation only starts once
      }, 200); // 200ms delay before starting the animation
    }
  }, [dropCount, imageUrls, animationStarted]);

  return (
    <div className="rain-container">
      {drops.map((drop) => (
        <motion.img
          key={drop.id}
          src={drop.imageUrl} // Use the randomly selected image URL
          className="rain-drop"
          style={{
            left: `${drop.xPosition}vw`,
            width: `${drop.size}px`, // Random size of the image
          }}
          initial={{ y: "-100px", opacity: 0 }} // Start above the viewport
          animate={{ 
            y: "100vh", // Fall to the bottom
            opacity: [0, 1, 0], // Fade in and then out
          }} 
          transition={{
            delay: drop.delay,
            duration: drop.duration,
            ease: "linear",
            repeat: 0, // No repeating, each drop falls once
          }}
        />
      ))}
    </div>
  );
};

export default ImageRain;

