// import "./App.css";
// import { PrivateRoutes } from "./routes/PrivateRoutes";
// import "../src/assets/css/style.bundle.css";
// // import '../src/assets/css/bootstrap.min.css'
// import "../src/assets/css/bootstrap.min.css.map";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "../src/assets/css/style.css";
// import "../src/assets/css/fontawesome.min.css";
// import './pages/components/PreLoader'


// function App() {

//   return (
//       <PrivateRoutes />
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PrivateRoutes } from "./routes/PrivateRoutes";
import PreLoader from "./pages/components/PreLoader";
import "./App.css";
import "../src/assets/css/style.bundle.css";
import '../src/assets/css/bootstrap.min.css'
import "../src/assets/css/bootstrap.min.css.map";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "../src/assets/css/fontawesome.min.css";
import './pages/components/PreLoader'

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Detect route changes

  useEffect(() => {
    // Show loader on every route change
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide loader after 3 seconds
    }, 800); // Adjust duration as needed

    return () => clearTimeout(timer);
  }, [location]); // Trigger loader on route change

  if (isLoading) {
    return <PreLoader />;
  }

  return <PrivateRoutes />;
}

export default App;
