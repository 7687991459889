import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../helpers/AssetHelpers";
import { motion, AnimatePresence, useCycle, color } from "framer-motion";
import { MenuToggle } from "./MenuToggle";
import AnimatedText from "./Animatetext";
import styled from "styled-components";

const Header = ({ element }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [isVisible, setIsVisible] = useState(false);

  const toggleMenu = () => {
    setTimeout(() => {
      setShowMenu(!showMenu);
    }, 250);
  };

  useEffect(() => {
    // Set a timeout to change the visibility after 3 seconds
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  let navigate = useNavigate();
  const routeChange = () => {
    let path = `../../`;
    navigate(path);
  };

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 40px 40px )`,

      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: "circle(30px at 40px 40px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const variants3 = {
    hidden: { opacity: 0, scale: 0.8 }, // Start scaled down
    visible: (i) => ({
      opacity: 1,
      scale: 1, // Scale to original size
      transition: {
        type: "spring",
        stiffness: 50,
        opacity: { duration: 0.5 }, // Fade-in duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-in duration and bounce effect
      },
    }),
    exit: {
      opacity: 0,
      scale: 0.8, // Scale down when exiting
      transition: {
        opacity: { duration: 0.5 }, // Fade-out duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-out duration and bounce effect
      },
    },
  };

  const variants2 = {
    hidden: { opacity: 0, y: -100 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      y: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const variants4 = {
    hidden: { opacity: 0, y: 200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      y: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  return (
    <>
      <div
        className={` h-100vh w-100 scroll-y`}
        style={{
          overflowX: "hidden",
          backgroundColor: showMenu ? "#dab692" : "",
        }}
      >
        {isVisible ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-between align-items-start">
                <motion.nav
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants4}
                  animate={isOpen ? "open" : "closed"}
                >
                  <motion.div className="background" variants={sidebar} />
                  <MenuToggle
                    toggle={() => {
                      toggleOpen();
                      toggleMenu();
                    }}
                  />
                </motion.nav>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  className="corner_img"
                >
                  <motion.img
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    variants={variants4}
                    className="logo_img cursor-pointer"
                    onClick={routeChange}
                    src={toAbsoluteUrl("media/rangoli_logo.svg")}
                    alt="Logo"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <AnimatePresence>
          {showMenu && (
            <motion.div
              className="container-fluid mt-lg-n20 "
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <div className="row app-container">
                <div className="col-lg-7 col-md-4">
                  <div className="row">
                    <motion.li
                      whileHover={{ scale: 1.1, originX: 0 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="col-lg-12 mb-5 list-style-none"
                    >
                      <Link to="../" className="menu_link">
                        <AnimatedText text={"Home"} />
                      </Link>
                    </motion.li>

                    <motion.li
                      whileHover={{ scale: 1.1, originX: 0 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="col-lg-12 mb-5 list-style-none"
                    >
                      <Link to="../aboutus" className="menu_link">
                        <AnimatedText text={"Our Journey"} />
                      </Link>
                    </motion.li>
                    <motion.li
                      whileHover={{ scale: 1.1, originX: 0 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="col-lg-12 mb-5 list-style-none"
                    >
                      <Link to="../deliciousdiscovery" className="menu_link ">
                        <AnimatedText text={"Delicious Discovery"} />
                      </Link>
                    </motion.li>

                    <motion.li
                      whileHover={{ scale: 1.1, originX: 0 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="col-lg-12 mb-5 list-style-none"
                    >
                      <Link to="../magicalmoments" className="menu_link">
                        <AnimatedText text={"Magical Moments"} />
                      </Link>
                    </motion.li>

                    <motion.li
                      whileHover={{ scale: 1.1, originX: 0 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="col-lg-12  mb-5 list-style-none"
                    >
                      <Link to="../franchiseinquiry" className="menu_link">
                        <AnimatedText text={"Franchise Inquiry"} />
                      </Link>
                    </motion.li>

                    <motion.li
                      whileHover={{ scale: 1.1, originX: 0 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="col-lg-12  mb-5 list-style-none"
                    >
                      <Link to="../career" className="menu_link">
                        <AnimatedText text={"Work With Us"} />
                      </Link>
                    </motion.li>
                    <motion.li
                      whileHover={{ scale: 1.1, originX: 0 }}
                      transition={{ type: "spring", stiffness: 300 }}
                      className="col-lg-12 mb-5 list-style-none"
                    >
                      <Link to="../connectwithus" className="menu_link">
                        <AnimatedText text={"Contact Us"} />
                      </Link>
                    </motion.li>
                  </div>
                </div>
              </div>
              <div style={{ borderTop: "1px solid rgb(226, 214, 189)" , marginRight:"2rem" , marginLeft :"2rem" }}></div>

              
              <div className="row  mt-5 " style={{paddingLeft:"2rem" , paddingRight:"2rem"}}>
                <div className="col-lg-3 col-md-4 col-sm-6 ">
                  <Link className="social_media_link d-flex align-items-center">
                    <i className="fa-solid fa-phone-volume text-primary-brown fs-2 pe-3 "></i>
                    <motion.li
                      whileHover={{ scale: 1.03, originX: 0, willChange: true }}
                      className="list-style-none"
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <AnimatedText text={"123 456 789"} />
                    </motion.li>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <Link
                    to={"mailto:rangoliicecream.com"}
                    className="social_media_link d-flex align-items-center"
                  >
                    <i className="fa-solid fa-envelope text-primary-brown fs-2 pe-3"></i>
                    <motion.li
                      whileHover={{ scale: 1.03, originX: 0, willChange: true }}
                      className="list-style-none"
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <AnimatedText text={"rangoliicecream.com"} />
                    </motion.li>{" "}
                  </Link>
                </div>
                <div className="col-lg-3"></div>
                <div className="col-lg-3">
                  <div className="text-lg-end text-center my-5 my-sm-3 my-md-0">
                    <Link className="social_media_link best-solutions-service2 mx-2">
                      <motion.img
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        variants={variants3}
                        whileHover={{ y: "-20px", scale: 1.1 }}
                        className="social_media_logo"
                        src={toAbsoluteUrl("media/insta.svg")}
                        alt="instagram-logo"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.9, ease: "easeOut" }}
          >
            {!showMenu && { ...element }}
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};

export { Header };
