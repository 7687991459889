
import React from "react";
import { motion } from "framer-motion";

const BouncingText = ({ text }) => {
  // Splitting text into individual letters
  const letters = Array.from(text);

  // Variants for each letter
  const letterAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
    hover: {
      scale: 1.3, // Enlarge slightly on hover
      y: -10, // Move upwards on hover
      
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 10,
      },
    },
  };

  return (
    <motion.div
      // style={{ overflow: "hidden" }} // Removed display: flex
      initial="hidden"
      className="cursor-pointer"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ staggerChildren: 0.05 }}
    >
      {letters.map((letter, index) => (
        <motion.span
        
          key={index}
          variants={letterAnimation}
          style={{ display: "inline-block" }} // This allows each letter to behave like a block, enabling hover animations
          whileHover="hover" // Add hover animation for each letter
        >
          {letter === " " ? "\u00A0" : letter}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default BouncingText;


// import React from "react";
// import { motion } from "framer-motion";

// const BouncingText = ({ text }) => {
//   // Splitting text into individual letters
//   const letters = Array.from(text);

//   // Variants for each letter
//   const letterAnimation = {
//     hidden: { opacity: 0, y: 50 },
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         type: "spring",
//         stiffness: 300,
//         damping: 20,
//       },
//     },
//     hover: {
//       scale: 1.2, // Slightly reduced scale to minimize blurring
//       y: -10,
//       transition: {
//         type: "spring",
//         stiffness: 300,
//         damping: 10,
//       },
//     },
//   };

//   return (
//     <motion.div
//       initial="hidden"
//       className="cursor-pointer"
//       whileInView="visible"
//       viewport={{ once: true }}
//       transition={{ staggerChildren: 0.05 }}
//       style={{ display: "inline-block", willChange: "transform" }} // Added will-change for better rendering
//     >
//       {letters.map((letter, index) => (
//         <motion.span
//           key={index}
//           variants={letterAnimation}
//           style={{
//             display: "inline-block",
//             transform: "translateZ(0)", // Added translateZ(0) to reduce blurring
//             willChange: "transform", // Optimizing for transform changes
//           }}
//           whileHover="hover"
//         >
//           {letter === " " ? "\u00A0" : letter}
//         </motion.span>
//       ))}
//     </motion.div>
//   );
// };

// export default BouncingText;
