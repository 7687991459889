import React, { useState, useEffect } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, AnimatePresence } from "framer-motion";
import { ImageGallery } from "react-image-grid-gallery";
import BouncingText from "./components/BouncingText";
import AnimatedText from "./components/Animatetext";

const images = [
  toAbsoluteUrl("media/magicalmoments/kid1.png"),
  toAbsoluteUrl("media/magicalmoments/kid2.png"),
  toAbsoluteUrl("media/magicalmoments/kid3.png"),
  toAbsoluteUrl("media/magicalmoments/kid4.png"),
  toAbsoluteUrl("media/magicalmoments/kid1.png"),
  toAbsoluteUrl("media/magicalmoments/kid2.png"),
  toAbsoluteUrl("media/magicalmoments/kid3.png"),
  toAbsoluteUrl("media/magicalmoments/kid4.png"),
  toAbsoluteUrl("media/magicalmoments/kid1.png"),
  toAbsoluteUrl("media/magicalmoments/kid2.png"),
  toAbsoluteUrl("media/magicalmoments/kid3.png"),
  toAbsoluteUrl("media/magicalmoments/kid4.png"),
  toAbsoluteUrl("media/magicalmoments/kid1.png"),
  toAbsoluteUrl("media/magicalmoments/kid2.png"),
  toAbsoluteUrl("media/magicalmoments/kid3.png"),
  toAbsoluteUrl("media/magicalmoments/kid4.png"),
];

const MagicalMomentsWrapper = () => {
  const [sliderStates, setSliderStates] = useState([
    { currentIndex: 0, direction: 0 },
    { currentIndex: 0, direction: 0 },
    { currentIndex: 0, direction: 0 },
  ]);

  const [radius, setRadius] = useState(calculateRadius(window.innerWidth));
  var imagesPerSlide = radius;

  useEffect(() => {
    const handleResize = () => {
      setRadius(calculateRadius(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateRadius(width) {
    if (width > 500 && width < 1691) {
      return 3;
    } else if (width < 500) {
      return 1;
    } else {
      return 4;
    }
  }

  const handlePrevSlide = (sliderIndex) => {
    setSliderStates((prevState) => {
      const newStates = [...prevState];
      newStates[sliderIndex].direction = -1;
      newStates[sliderIndex].currentIndex =
        prevState[sliderIndex].currentIndex === 0
          ? images.length - imagesPerSlide
          : prevState[sliderIndex].currentIndex - 1;
      return newStates;
    });
  };

  const handleNextSlide = (sliderIndex) => {
    setSliderStates((prevState) => {
      const newStates = [...prevState];
      newStates[sliderIndex].direction = 1;
      newStates[sliderIndex].currentIndex =
        prevState[sliderIndex].currentIndex >= images.length - imagesPerSlide
          ? 0
          : prevState[sliderIndex].currentIndex + 1;
      return newStates;
    });
  };

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      position: "absolute",
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      position: "relative",
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      position: "absolute",
    }),
  };

  const [width, setWidth] = useState(calculateWidth(window.innerWidth));
  var columnWidth = width;

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateWidth(width) {
    if (width > 1085 && width < 1425) {
      return 230;
    } else if (width > 550 && width < 1085) {
      return 230;
    } else if (width < 550) {
      return 130;
    } else {
      return 330;
    }
  }

  const images2 = [
    { src: toAbsoluteUrl("media/newsevents/eventimage1.png") },
    { src: toAbsoluteUrl("media/newsevents/eventimage2.png") },
    { src: toAbsoluteUrl("media/new_arrival/image1.png") },
    { src: toAbsoluteUrl("media/new_arrival/image2.png") },
    { src: toAbsoluteUrl("media/new_arrival/image3.png") },
    { src: toAbsoluteUrl("media/new_arrival/image4.png") },
    { src: toAbsoluteUrl("media/connectwithus/image1.png") },
    { src: toAbsoluteUrl("media/new_arrival/image2.png") },
    { src: toAbsoluteUrl("media/new_arrival/image3.png") },
    { src: toAbsoluteUrl("media/new_arrival/image4.png") },
    { src: toAbsoluteUrl("media/connectwithus/image1.png") },
    { src: toAbsoluteUrl("media/new_arrival/image2.png") },
    { src: toAbsoluteUrl("media/new_arrival/image3.png") },
    { src: toAbsoluteUrl("media/new_arrival/image4.png") },
    { src: toAbsoluteUrl("media/connectwithus/image1.png") },
    { src: toAbsoluteUrl("media/connectwithus/image2.png") },
    { src: toAbsoluteUrl("media/connectwithus/image3.png") },
    { src: toAbsoluteUrl("media/connectwithus/image4.png") },
  ];

  const duplicatedImages = Array.from({ length: 14 }, () => [...images2]).flat(); // 14x duplication


  const variants2 = {
    hidden: { opacity: 0, x: -200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const variants4 = {
    hidden: { opacity: 0, x: 300 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const variants5 = {
    hidden: { opacity: 0, y: 300 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      y: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const data = (index) => {
    var slide = index % 3;
    if (slide == 0) {
      return variants2;
    } else if (slide == 1) {
      return variants4;
    } else {
      return variants5;
    }
  };
  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };
  return (
    <>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={variants2} // Apply the text animation
        className="d-flex justify-content-center main_title_news"
      >
       <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          
          className="text-center new_arrival_title"
        >
          Magical Moments with Rangoli
        </motion.p>
      </motion.div>

      <motion.div className="moments_section">
        {sliderStates.map((_, index) => {
          const currentImages = images.slice(
            sliderStates[index].currentIndex,
            sliderStates[index].currentIndex + imagesPerSlide
          );

          return (
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={data(index)}
              className="moment_box"
              style={{ marginTop: "2rem" }}
              key={`moment_box_${index}`}
            >
              <div className="moment_image cursor-pointer">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.8 + index * 0.2,
                    type: "spring",
                    stiffness: 200,
                    damping: 10,
                  }}
                  variants={{
                    visible: { opacity: 1, scale: 1, y: 0 },
                    hidden: { opacity: 0, scale: 0.5, y: 100 },
                  }}
                  whileTap={{
                    scale: 1.1,
                    // rotate: 15, // Tilt the image down on hover
                    // transformOrigin: "top left", // Fix the top-left corner while the rest "hangs"
                  }}
                  src={toAbsoluteUrl("media/magicalmoments/image1.png")}
                  alt=""
                />
              </div>
              <div className="moment_details">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={data(index)}
                  className="moment_scheme"
                >
                  <p>
                    Grand Opening Of Rangoli Icecream’s “41st Outlet” In Our
                    AhmedabadGet 50%off on all icecream n Shrikhand icecream on
                    sunday
                  </p>
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={data(index)}
                  className="moment_date"
                >
                  On 9th June 2024 10am Onwards
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={data(index)}
                  className="moment_location"
                >
                  Ground Floor, Shop No-2, Empire Skyline, Science City Rd,
                  Hebatpur, Ahmedabad
                </motion.div>
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={data(index)}
                  className="slider_container"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    height: "auto",
                  }}
                >
                  <div
                    className="slider"
                    style={{ display: "flex", position: "relative" }}
                  >
                    <AnimatePresence
                      initial={false}
                      custom={sliderStates[index].direction}
                    >
                      {currentImages.map((image, imgIndex) => (
                        <motion.div
                          key={`${image}_${
                            sliderStates[index].currentIndex + imgIndex
                          }`}
                          className="slider-image"
                          custom={sliderStates[index].direction}
                          initial="enter"
                          animate="center"
                          exit="exit"
                          variants={variants}
                          transition={{
                            x: { type: "spring", stiffness: 300, damping: 30 },
                            opacity: { duration: 0.2 },
                          }}
                          style={{
                            flexShrink: 0,
                          }}
                        >
                          <img
                            src={image}
                            alt={`Slide ${
                              sliderStates[index].currentIndex + imgIndex + 1
                            }`}
                          />
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </div>
                  <div className="slider_buttons">
                    <button
                      className="slider_button next-button"
                      onClick={() => handleNextSlide(index)}
                    >
                      <FontAwesomeIcon
                        icon={faGreaterThan}
                        style={{
                          transform: "rotate(180deg)",
                          fontSize: "12px",
                        }}
                      />
                    </button>
                    <button
                      className="slider_button prev-button"
                      onClick={() => handlePrevSlide(index)}
                    >
                      <FontAwesomeIcon icon={faGreaterThan} />
                    </button>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          );
        })}
      </motion.div>

      <div className="layered_text2 container">
        <div className="row text-center">
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants4}
            whileTap={{ scale: 0.9 }}
            className="text1"
          >
            Happy Moments With Rangoli
          </motion.p>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants5}
            whileTap={{ scale: 0.9 }}
            className="text2"
          >
            Happy Moments With Rangoli
          </motion.p>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants2}
            whileTap={{ scale: 0.9 }}
            className="text3"
          >
            Happy Moments With Rangoli
          </motion.p>
        </div>
      </div>
      <div className="magical_a">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={variants2}
          className="magical_gallery"
        >
          <ImageGallery
            imagesInfoArray={duplicatedImages} // Use the duplicated array for infinite effect
            columnCount={"auto"}
            columnWidth={columnWidth}
            gapSize={18}
          />
        </motion.div>
      </div>

      <Footer />
    </>
  );
};

const MagicalMoments = () => {
  return <Header element={<MagicalMomentsWrapper />} />;
};

export { MagicalMoments };
