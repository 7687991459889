import React, { useEffect, useState, useCallback, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];

const GoogleMapComponent = ({
    latitude,
    longitude,
    onSelectLocation,
    height = '100vh', // Default to '100vh' if height prop is not provided
}) => {
    const [selectedPlace, setSelectedPlace] = useState({
        lat: latitude || 41.3113642,
        lng: longitude || 69.28267819999999,
    });
    const markerRef = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
        if (latitude && longitude) {
            const newLocation = { lat: latitude, lng: longitude };
            setSelectedPlace(newLocation);
            if (mapRef.current) {
                mapRef.current.panTo(newLocation);
            }
            animateMarker();
        }
    }, [latitude, longitude]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "", // Add your API key here
        libraries,
    });

    const onMapClick = useCallback((event) => {
        const newMarker = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };

        setSelectedPlace(newMarker);
        if (mapRef.current) {
            mapRef.current.panTo(newMarker);
        }
        animateMarker();
        reverseGeocode(newMarker, newMarker);
    }, []);

    const onMarkerDragEnd = useCallback((event) => {
        const newPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };

        setSelectedPlace(newPosition);
        if (mapRef.current) {
            mapRef.current.panTo(newPosition);
        }
        animateMarker();
        reverseGeocode(newPosition, newPosition);
    }, []);

    const animateMarker = () => {
        if (markerRef.current) {
            markerRef.current.setAnimation(window.google.maps.Animation.BOUNCE);
            setTimeout(() => {
                markerRef.current.setAnimation(null);
            }, 200); // Bounce animation duration
        }
    };

    const extractLocationDetails = (result, exactCoordinates) => {
        const addressComponents = result.address_components;
        const locationDetails = {
            location: result.formatted_address,
            state: getAddressComponent(addressComponents, 'administrative_area_level_1'),
            city: getAddressComponent(addressComponents, 'locality'),
            country: getAddressComponent(addressComponents, 'country'),
            latitude: exactCoordinates.lat,
            longitude: exactCoordinates.lng,
        };
        return locationDetails;
    };

    const reverseGeocode = async (marker, exactCoordinates) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: marker }, (results, status) => {
            if (status === 'OK') {
                let locationDetails = {
                    location: 'Unknown location',
                    state: '',
                    city: '',
                    country: '',
                    latitude: exactCoordinates.lat,
                    longitude: exactCoordinates.lng,
                    plusCode: '',
                };

                for (let i = 0; i < results.length; i++) {
                    if (results[i].plus_code && results[i].plus_code.global_code) {
                        locationDetails = extractLocationDetails(results[i], exactCoordinates);
                        locationDetails.plusCode = results[i].plus_code.global_code;
                        break;
                    } else if (i === 0) {
                        // Use the first result if no plus code is found
                        locationDetails = extractLocationDetails(results[i], exactCoordinates);
                    }
                }
                onSelectLocation(locationDetails);
            } else {
                console.error('Geocoder failed due to: ' + status);
                onSelectLocation({
                    location: 'Unknown location',
                    state: '',
                    city: '',
                    country: '',
                    latitude: exactCoordinates.lat,
                    longitude: exactCoordinates.lng,
                });
            }
        });
    };

    const getAddressComponent = (addressComponents, type) => {
        const component = addressComponents.find((component) => component.types.includes(type));
        return component ? component.long_name : '';
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={{ width: '100%', height }} // Apply dynamic height
            zoom={10}
            center={selectedPlace}
            onClick={onMapClick}
            mapContainerClassName='w-100 shadow-sm rounded-4'
            onLoad={(map) => (mapRef.current = map)}
        >
            <Marker
                position={selectedPlace}
                draggable={true}
                onDragEnd={onMarkerDragEnd}
                onLoad={(marker) => (markerRef.current = marker)}
            />
        </GoogleMap>
    );
};

export default GoogleMapComponent;
