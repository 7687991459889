// import * as React from "react";
// import { motion } from "framer-motion";

// const Path = (props) => (
//   <motion.path
//     fill="#FF0000"
//     strokeWidth="3"
//     stroke="hsl(0, 0%, 18%)"
//     strokeLinecap="round"
//     {...props}
//   />
// );

// export const MenuToggle = ({ toggle }) => (
//   <button onClick={toggle} className="header_button bg-secondary-red">
//     <svg width="23" height="23" fill="#FF0000" viewBox="0 0 23 24">
//       <Path
//         fill="#FF0000"
//         variants={{
//           closed: { d: "M 2 2.5 L 20 2.5" },
//           open: { d: "M 3 16.5 L 17 2.5" },
//         }}
//       />
//       <Path
//         fill="#FF0000"
//         d="M 2 9.423 L 20 9.423"
//         variants={{
//           closed: { opacity: 1 },
//           open: { opacity: 0 },
//         }}
//         transition={{ duration: 0.1 }}
//       />
//       <Path
//         fill="#FF0000"
//         variants={{
//           closed: { d: "M 2 16.346 L 20 16.346" },
//           open: { d: "M 3 2.5 L 17 16.346" },
//         }}
//       />
//     </svg>
//   </button>
// );

import * as React from "react";
import { motion } from "framer-motion";

const Path = (props) => (
  <motion.path
    strokeWidth="3"
    stroke="#CB9EA8" // Change the stroke color to #FF69B4
    strokeLinecap="round"
    {...props}
  />
);

export const MenuToggle = ({ toggle }) => (
  <button
    onClick={toggle}
    className="header_button"
    style={{
      backgroundColor: "#fdeaee", // Circle background color
      borderRadius: "50%", // Make it circular
      width: "50px", // Adjust width and height as needed
      height: "50px",
      display: "flex", // Flexbox to center the icon
      alignItems: "center",
      paddingLeft: "7px",
      paddingTop: "4px",
      justifyContent: "center",
    }}
  >
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none">
      <Path
        fill="none"
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        fill="none"
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        fill="none"
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </button>
);
