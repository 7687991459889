import axios from "axios";

export const URL =
{
    uatgogagnerurl:"https://api.gogagner.com/rangoli"
}

// API HEADER
export const apiHeader = (isFormData) => {


    if (!isFormData) {
        return {
            headers: {
                'apitoken':'b361d6655jrsd4dd02efaf412ed585sd',
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Access-Control-Allow-Origin" : "*",
            },
        };
    }

    if (isFormData) {
        return {
            headers: {
                'apitoken':'b361d6655jrsd4dd02efaf412ed585sd',
                'Content-Type': 'multipart/form-data',
            },
        };
    }

};

export const postData = async (api, data, headers, showAlert = true) => {
    
    try {

        const url = `${URL.uatgogagnerurl}${api}`;

        const response = await axios.post(url, data, headers)
      
      
        return response;
        

    } catch (error) {

        
        let networkError = error.message === "Network Error";
        if (showAlert == true) {
           
        }
        return undefined;
    }
};

// API CALL for GET method
export const getData = async (api, params, headers, showAlert = true) => {
    
    try {
        const url = `${URL.uatgogagnerurl}${api}`;
        
        
        const response = await axios.get(url, { params: params, headers: headers, })
      
        return response;
    } catch (error) {
        console.log("Error:", error.message);

        let networkError = error.message === "Network Error";
        if (showAlert) {
       
        }

        return undefined;
    }
};
