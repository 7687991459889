import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Carousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [direction, setDirection] = useState("right");

    // Automatic slide change
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval);
    }, [currentIndex]);

    const slideVariants = {
        hiddenRight: {
            x: "100%", // Start from the right
            opacity: 0,
            scale: 0.8, // Slightly shrink the image for a smoother effect
        },
        hiddenLeft: {
            x: "-100%", // Start from the left
            opacity: 0,
            scale: 0.8, // Slightly shrink the image for a smoother effect
        },
        visible: {
            x: "0", // Center
            opacity: 1,
            scale: 1, // Return to original scale
            transition: {
                duration: 1.2, // Longer duration for a smoother effect
                ease: [0.42, 0, 0.58, 1], // Smooth easing function (cubic-bezier)
            },
        },
        exit: {
            opacity: 0,        // Fade out
            scale: 0.5,        // Shrink the image
            transition: {
                duration: 0.8, // Duration for the exit
                ease: [0.42, 0, 0.58, 1], // Smooth easing function
            },
        },
    };

    const handleNext = () => {
        setDirection("right");
        setCurrentIndex((prevIndex) =>
            prevIndex + 1 === images.length ? 0 : prevIndex + 1
        );
    };

    const handlePrevious = () => {
        setDirection("left");
        setCurrentIndex((prevIndex) =>
            prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
        );
    };

    const handleDotClick = (index) => {
        setDirection(index > currentIndex ? "right" : "left");
        setCurrentIndex(index);
    };

    const dotsVariants = {
        initial: {
            y: 0,
        },
        animate: {
            y: -10,
            scale: 1.3, // Slightly larger scale for more emphasis
            transition: { type: "spring", stiffness: 800, damping: 15 }, // Adjusted spring for smoother motion
        },
        hover: {
            scale: 1.2,
            transition: { duration: 0.3 }, // Slightly slower hover effect
        },
    };

    return (
        <div className="carousel">
            <div className="carousel-images" style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.img
                        key={currentIndex}
                        src={images[currentIndex]}
                        initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
                        animate="visible"
                        exit="exit"
                        variants={slideVariants}
                        custom={direction}
                        style={{ maxWidth: "100%", maxHeight: "100%" }} // Ensure image takes full space available
                    />
                </AnimatePresence>
            </div>
            <div className="carousel-indicator">
                {images.map((_, index) => (
                    <motion.div
                        key={index}
                        className={`dot ${currentIndex === index ? "active" : ""}`}
                        onClick={() => handleDotClick(index)}
                        initial="initial"
                        animate={currentIndex === index ? "animate" : ""}
                        whileHover="hover"
                        variants={dotsVariants}
                    ></motion.div>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
