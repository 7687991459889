import React, { useEffect, useRef, useState } from "react";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { Header } from "./components/Header";
import { motion, AnimatePresence, animate } from "framer-motion";
import Carousel from "./components/Carousel";
import Select from "react-select";
import GoogleMapComponent from "./components/GoogleMapComponent";
import { Footer } from "./components/Footer";
import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwipeable } from "react-swipeable";
// Dummy data for names, images, and titles
import AnimatedText from "./components/Animatetext";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { DeliciousDiscovery } from "./DeliciousDiscovery";
const data = [
  {
    name: "Ice Cream",
    activeColor: "#D4A951",
    backgroundImage: "media/home_banner/icecream_banner.svg",
    title: "ICE CREAM",
  },
  {
    name: "Sundae",
    activeColor: "#D86D83",
    backgroundImage: "media/home_banner/sundae_banner.svg",
    title: "SUNDAE",
  },
  {
    name: "M.O.M Shake",
    activeColor: "#D4A951",
    backgroundImage: "media/home_banner/shake_banner.svg",
    title: "M.O.M Shake",
  },

  { name: "", activeColor: "", backgroundImage: "", title: "" },
  { name: "", activeColor: "", backgroundImage: "", title: "" },
  { name: "", activeColor: "", backgroundImage: "", title: "" },
  {
    name: "Ice Cream Shrikhand",
    activeColor: "#D86D83",
    backgroundImage: "media/home_banner/shrikhand_banner.svg",
    title: "ICE CREAM SHRIKHAND",
  },
  {
    name: "Sizzling Brownie",
    activeColor: "#D4A951",
    backgroundImage: "media/home_banner/brownie_banner.svg",
    title: "SIZZLING BROWNIE",
  },

  {
    name: "Stick Bar",
    activeColor: "#D86D83",
    backgroundImage: "media/home_banner/conecandy_banner.svg",
    title: "STICK BAR",
  },
];

const HomeWrapper = () => {
  const [selected, setSelected] = useState(data[0]);
  const [longitude] = useState("");
  const [latitude] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("1");
  const [selectedCategoryImage, setSelectedCategoryImage] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [svgCode, setSvgCode] = useState("");
  const [isCodeVisible, setIsCodeVisible] = useState(false);

  const categories = [
    {
      id: "1",
      images: toAbsoluteUrl("media/arrival_section_below/image1.svg"),
      title: "Ice Cream",
    },
    {
      id: "2",
      images: toAbsoluteUrl("media/arrival_section_below/image2.svg"),
      title: "Sundae",
    },
    {
      id: "3",
      images: toAbsoluteUrl("media/arrival_section_below/image3.svg"),
      title: "M.O.M Shake",
    },
    {
      id: "4",
      images: toAbsoluteUrl("media/arrival_section_below/image5.svg"),
      title: "Ice Cream Shrikhand",
    },
    {
      id: "5",
      images: toAbsoluteUrl("media/arrival_section_below/image6.svg"),
      title: "Sizzling Brownie",
    },

    {
      id: "6",
      images: toAbsoluteUrl("media/arrival_section_below/image8.svg"),
      title: "Stick Bar",
    },
  ];
  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
  });

  const [width, setWidth] = useState(calculateWidth(window.innerWidth));
  var imagesPerSlide = width;

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateWidth(width) {
    if (width > 350 && width < 500) {
      return 2;
    } else if (width < 350) {
      return 1;
    } else {
      return 3;
    }
  }

  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const prevSlide = () => {
    setDirection(-1);

    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? categories.length - imagesPerSlide : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setDirection(1);

    setCurrentIndex((prevIndex) =>
      prevIndex >= categories.length - imagesPerSlide ? 0 : prevIndex + 1
    );
  };

  const currentImages = categories.slice(
    currentIndex,
    currentIndex + imagesPerSlide
  );

  const categoryimage = [
    {
      categoryId: "1",
      images: [
        { image: toAbsoluteUrl("media/arrival_section_below/icecream1.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/icecream2.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/icecream3.png") },
      ],
      subCategory: [
        { title: "Kesar Pista" },
        { title: "Choco Munch" },
        { title: "Silk Choclate" },
        { title: "Red Velvet" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "1" },
      ],
    },
    {
      categoryId: "2",
      images: [
        { image: toAbsoluteUrl("media/arrival_section_below/sundae1.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/sundae2.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/sundae3.png") },
      ],
      subCategory: [
        { title: "Choclate Crunch" },
        { title: "Red Velvet" },
        { title: "American Sundae" },
        { title: "Crunchy kit-Kat" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "2" },
      ],
    },
    {
      categoryId: "3",
      images: [
        { image: toAbsoluteUrl("media/arrival_section_below/shake1.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/shake2.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/shake3.png") },
      ],
      subCategory: [
        { title: "kit-Kat" },
        { title: "Choco Brownie" },
        { title: "Nutty Choco" },
        { title: "Classic Oreo" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "3" },
      ],
    },
    {
      categoryId: "4",
      images: [
        { image: toAbsoluteUrl("media/arrival_section_below/shrikhand1.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/shrikhand2.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/shrikhand3.png") },
      ],
      subCategory: [
        { title: "Cream & Bonbon" },
        { title: "Mix Fruit" },
        { title: "Dry Fruit Khajana" },
        { title: "Rajbhog" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "4" },
      ],
    },
    {
      categoryId: "5",
      images: [
        { image: toAbsoluteUrl("media/arrival_section_below/brownie1.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/brownie2.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/brownie3.png") },
      ],
      subCategory: [
        { title: "Kit-Kat Sizzling Brownie" },
        { title: "Sizzling Choco Brownie" },
        { title: "Sizzling Brownie" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "5" },
      ],
    },
    {
      categoryId: "6",
      images: [
        { image: toAbsoluteUrl("media/arrival_section_below/stickbar2.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/stickbar3.png") },
        { image: toAbsoluteUrl("media/arrival_section_below/stickbar1.png") },
      ],
      subCategory: [
        { title: "Jamun" },
        { title: "Centre Burst" },
        { title: "Biscoffee Cone" },
        { title: "Red Velvet Cone" },
        { title: "View More", path: "deliciousdiscovery", categoryId: "6" },
      ],
    },
  ];

  useEffect(() => {
    const filteredImages = categoryimage.filter(
      (item) => item.categoryId === selectedCategory
    );
    // Update the state based on the count
    if (filteredImages[0].images.length > 0) {
      setSelectedCategoryImage(filteredImages[0].images);
      setSelectedSubCategories(filteredImages[0].subCategory);
    } else {
      setSelectedCategoryImage([]); // Clear the state if no images match
    }
  }, [selectedCategory]);

  const handleNameClick = (item) => {
    setSelected(item);
  };

  // const radius = 35; // Percentage of the viewport width for responsiveness
  const [radius, setRadius] = useState(calculateRadius(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setRadius(calculateRadius(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function calculateRadius(width) {
    if (width < 421) {
      return 43;
    } else if (width < 576) {
      return 42;
    } else if (width < 721) {
      return 42;
    } else if (width < 768) {
      return 42;
    } else if (width < 775) {
      return 42;
    } else if (width < 882) {
      return 39;
    } else if (width < 1200) {
      return 36;
    } else {
      return 35;
    }
  }

  const newArrivalImages = [
    `${toAbsoluteUrl("media/new_arrival/image1.png")}`,
    `${toAbsoluteUrl("media/new_arrival/image2.png")}`,
    `${toAbsoluteUrl("media/new_arrival/image3.png")}`,
    `${toAbsoluteUrl("media/new_arrival/image4.png")}`,
    // Add more image URLs as needed
  ];

  const journeyImages = [
    `${toAbsoluteUrl("media/journey_section/image1.png")}`,
    `${toAbsoluteUrl("media/journey_section/image2.png")}`,
    `${toAbsoluteUrl("media/journey_section/image3.png")}`,
  ];

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      padding: "5px 5px", // Add padding to each dropdown option
      color: "gray",
      fontSize: "16px",
      fontFamily: "gilroy semibold", // Apply Gilroy SemiBold font
      fontWeight: 100, // Ensure SemiBold weight is applied
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "10px", // Add border-radius to the control (input area)
      padding: "5px 5px", // Add padding to each dropdown option
      boxShadow: "none",
      fontFamily: "gilroy semibold", // Apply Gilroy font to the control
      fontWeight: 100,
      "&:hover": {
        boxShadow: "1px 1px 5px white",
      },
      borderColor: "white",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the vertical line next to the dropdown icon
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000", // Customize the color of the selected value
      fontFamily: "gilroy semibold", // Apply Gilroy font to the selected value
      fontWeight: 100,
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: "gilroy semibold", // Apply Gilroy font to the menu options
      fontWeight: 100,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#333", // Different color when selected
      backgroundColor: state.isSelected ? "#007bff" : "#fff",
      fontFamily: "", // Apply Gilroy font to options
      fontWeight: 100,
      "&:hover": {
        backgroundColor: "#80bdff", // Background on hover
        color: "#fff",
      },
    }),
  };

  // "homepage": "https://rangoliicecream.com/",
  // <Router basename={"/"} >

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const variants = {
    hidden: { opacity: 0, y: -50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, type: "spring", stiffness: 50 },
    }),
  };
  const variants2 = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? -50 : screenSize < 739 ? -100 : -200,
    },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };
  const variants4 = {
    hidden: { opacity: 0, x: 200 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };

  const leftSide = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? -50 : screenSize < 739 ? -100 : -200,
    },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };
  const rightSide = {
    hidden: {
      opacity: 0,
      x: screenSize < 401 ? 50 : screenSize < 739 ? 100 : 200,
    },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };
  const variants5 = {
    hidden: { opacity: 0, y: 200 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.2,
        type: "spring",
        stiffness: 50,
        damping: 20,
      },
    }),
  };

  const variants3 = {
    hidden: { opacity: 0, scale: 0.8 }, // Start scaled down
    visible: (i) => ({
      opacity: 1,
      scale: 1, // Scale to original size
      transition: {
        type: "spring",
        stiffness: 50,
        opacity: { duration: 0.5 }, // Fade-in duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-in duration and bounce effect
      },
    }),
    exit: {
      opacity: 0,
      scale: 0.8, // Scale down when exiting
      transition: {
        opacity: { duration: 0.5 }, // Fade-out duration
        scale: { type: "spring", stiffness: 50, damping: 10 }, // Scale-out duration and bounce effect
      },
    },
  };

  const handleImageClick = async (selectedImage, color) => {
    try {
      const response = await fetch(selectedImage);
      const svgText = await response.text();

      // Parse and modify the SVG
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(svgText, "image/svg+xml");
      const svgElements = xmlDoc.querySelectorAll("*");

      svgElements.forEach((element) => {
        // Change fill color to desired value
        if (element.hasAttribute("fill")) {
          element.setAttribute("fill", color); // Change to your desired color
        }
        if (screenSize < 425) {
          element.setAttribute("width", "2.8rem"); // Decrease width when adjusting for white
          element.setAttribute("height", "2.8rem"); // Decrease width when adjusting for white
        }
      });

      const serializer = new XMLSerializer();
      const modifiedSvgText = serializer.serializeToString(xmlDoc);

      setSvgCode(modifiedSvgText);
      setIsCodeVisible(true);
    } catch (error) {
      console.error("Error fetching SVG:", error);
    }
  };

  useEffect(() => {
    handleImageClick(categories[0].images, "#e32024");
  }, []);

  const transformStyle = (x, y, angle, title) => {
    const rotationAngle = title === "M.O.M Shake" ? 98 : 95;

    return `translate(${x}vw, ${y}vw) rotate(${angle + rotationAngle}deg)`;
  };

  const breakText = (text) => {
    // Check if the text is either "Ice Cream Shrikhand" or "Sizzling Brownie"
    if (text === "Ice Cream Shrikhand" || text === "Sizzling Brownie") {
      // Split the text by space to separate the last word
      const words = text.split(" ");
      const lastWord = words.pop(); // Get the last word (Shrikhand or Brownie)
      const restOfText = words.join(" "); // Join the remaining words

      return (
        <>
          <div
            style={{
              lineHeight: "100%",
              marginTop: screenSize < 705 ? "-0.5rem" : "",
            }}
          >
            <span style={{ display: "block" }}>{restOfText}</span>

            <span
              style={{
                display: "block",
              }}
            >
              {lastWord}
            </span>
          </div>
        </>
      );
    }

    // If the condition isn't met, return the original text
    return text;
  };
  const settings = {
    slidesPerRow: 1,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1000, // Set the speed (in milliseconds)
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 743,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const animationVariants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: { opacity: 0, scale: 0.5, y: 100 },
  };

  return (
    <>
      <div className="row mt-lg-n20">
        <div className="col-lg-12 text-center">
          {/* <motion.p className="shiny-text">रंग जमा दे रंगोली...</motion.p> */}

          <img
            src={toAbsoluteUrl("media/home_banner/hinditext.png")}
            alt="Hindi text Rang Jama de rangoli"
            className="mb-5  pl-2 pr-2 hindi_text"
          />

          <p className="joy_text">We Make People Joyful by Sharing Joy</p>
        </div>
      </div>

      <div className="container-fluid pb-4">
        <div className="row">
          <div className="col-lg-12">
            <motion.h2
              className="text-center banner_title"
              key={selected.title}
              initial={{ duration: "3s", opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              style={{ color: selected.activeColor }}
            >
              <AnimatedText text={selected.title} />
            </motion.h2>
            <div className="d-flex justify-content-center">
              <div className="main_half_circle">
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    left:
                      selected.title === "ICE CREAM"
                        ? screenSize < 775
                          ? "-0.7%"
                          : screenSize < 882
                          ? "-1%"
                          : screenSize < 1200
                          ? "-0.4%"
                          : screenSize < 1300
                          ? "0.1%"
                          : "0.6%"
                        : selected.title === "SUNDAE"
                        ? screenSize < 465
                          ? "5%"
                          : screenSize < 882
                          ? "4%"
                          : screenSize < 1200
                          ? "4.5%"
                          : screenSize < 1300
                          ? "5.7%"
                          : "6.5%"
                        : selected.title === "M.O.M Shake"
                        ? screenSize < 600
                          ? "19%"
                          : screenSize < 882
                          ? "14%"
                          : screenSize < 1200
                          ? "15%"
                          : screenSize < 1300
                          ? "16%"
                          : "19%"
                        : selected.title === "ICE CREAM SHRIKHAND"
                        ? screenSize < 775
                          ? "80.7%"
                          : screenSize < 882
                          ? "80%"
                          : "80%"
                        : selected.title === "SIZZLING BROWNIE"
                        ? screenSize < 600
                          ? "94%"
                          : screenSize < 882
                          ? "93%"
                          : "91%"
                        : selected.title === "STICK BAR"
                        ? screenSize < 600
                          ? "98%"
                          : screenSize < 775
                          ? "98.6%"
                          : "98%"
                        : "0%",
                    top:
                      selected.title === "ICE CREAM"
                        ? screenSize < 313
                          ? "58%"
                          : screenSize < 375
                          ? "56%"
                          : screenSize < 465
                          ? "59%"
                          : screenSize < 600
                          ? "62%"
                          : screenSize < 775
                          ? "64%"
                          : screenSize < 882
                          ? "69%"
                          : screenSize < 1200
                          ? "72.5%"
                          : "75%"
                        : selected.title === "SUNDAE"
                        ? screenSize < 313
                          ? "36%"
                          : screenSize < 375
                          ? "34.6%"
                          : screenSize < 465
                          ? "35%"
                          : screenSize < 600
                          ? "39%"
                          : screenSize < 775
                          ? "41%"
                          : screenSize < 882
                          ? "43%"
                          : "46%"
                        : selected.title === "M.O.M Shake"
                        ? screenSize < 465
                          ? "12%"
                          : screenSize < 600
                          ? "13.4%"
                          : screenSize < 775
                          ? "19.7%"
                          : "18.4%"
                        : selected.title === "ICE CREAM SHRIKHAND"
                        ? screenSize < 775
                          ? "15%"
                          : screenSize < 882
                          ? "16%"
                          : screenSize < 1200
                          ? "17%"
                          : "20%"
                        : selected.title === "SIZZLING BROWNIE"
                        ? screenSize < 600
                          ? "41%"
                          : screenSize < 775
                          ? "37%"
                          : screenSize < 882
                          ? "41%"
                          : screenSize < 1200
                          ? "44%"
                          : "43%"
                        : selected.title === "STICK BAR"
                        ? screenSize < 600
                          ? "61%"
                          : screenSize < 775
                          ? "63%"
                          : screenSize < 882
                          ? "68%"
                          : screenSize < 1200
                          ? "72%"
                          : "74.6%"
                        : "0%",
                  }}
                  transition={{
                    duration: 1, // adjust duration to control the speed of the transition
                    ease: "easeInOut", // you can experiment with different easing functions
                  }}
                  key={selected.title}
                  style={{
                    position: "absolute",
                    width:
                      screenSize < 375
                        ? "8px"
                        : screenSize < 775
                        ? "12px"
                        : screenSize < 1200
                        ? "18px"
                        : "22.16px",
                    height:
                      screenSize < 375
                        ? "8px"
                        : screenSize < 375
                        ? "10px"
                        : screenSize < 775
                        ? "12px"
                        : screenSize < 1200
                        ? "18px"
                        : "22.16px",
                    borderRadius: "50%",
                    backgroundColor: selected.activeColor,
                  }}
                ></motion.div>

                <div className="half_circle">
                  <div className="image-title">
                    <motion.img
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                      className="banner_background cursor-pointer"
                      key={selected.backgroundImage}
                      src={toAbsoluteUrl(selected.backgroundImage)}
                      alt={selected.title}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 1.1 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="names">
              {data.map((item, index) => {
                const angle = 185 + index * (160 / (data.length - 1)); // Adjusted angle calculation for half-circle
                const x = radius * Math.cos(angle * (Math.PI / 180));
                const y = radius * Math.sin(angle * (Math.PI / 180));

                return (
                  <>
                    <motion.div
                      key={index}
                      className="name"
                      style={{
                        transform: transformStyle(x, y, angle, item.title),
                        color:
                          selected.name === item.name
                            ? item.activeColor
                            : "lightgray",
                      }}
                      onClick={() => handleNameClick(item)}
                      whileHover={{ color: item.activeColor }}
                    >
                      {breakText(item.name)}
                    </motion.div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* d-md-none */}
      <div className="arrival_section" style={{ position: "relative" }}>
        <img
          className="arrival_banner bigarrivalbanner"
          alt="images"
          src={toAbsoluteUrl("media/new_arrival/arrival_banner.png")}
        />

        <div
          className="arrival_content pt-5"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: "10000",
          }}
        >
          <div>
            <div className="d-flex  justify-content-between align-items-center">
              <div className="d-flex justify-content-start">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={leftSide}
                  src={toAbsoluteUrl("media/new_arrival/sidelineleft.png")}
                  alt="category"
                  className="side_lines"
                />
              </div>
              <motion.p
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                variants={animationVariants}
                className="text-center new_arrival_title pt-5"
              >
                New Arrival
              </motion.p>
              <div className="d-flex justify-content-end">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={rightSide}
                  src={toAbsoluteUrl("media/new_arrival/sidelineright.png")}
                  alt="category"
                  className="side_lines"
                />
              </div>
            </div>

            <div className="text-center">
              <motion.img
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                  duration: 0.8 + 0.2,
                  type: "spring",
                  stiffness: 200,
                  damping: 10,
                }}
                variants={{
                  visible: { opacity: 1, scale: 1, y: 0 },
                  hidden: { opacity: 0, scale: 0.5, y: 100 },
                }}
                className="text-image"
                src={toAbsoluteUrl("media/new_arrival/divider_7 3.png")}
                alt="images"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>

            <div className="new_arrival_images">
              <div className="d-flex justify-content-evenly align-items-center flex-wrap gap-4">
                {[
                  {
                    src: "media/new_arrival/image1.png",
                    alt: "Strawberry Ice Cream",
                  },
                  {
                    src: "media/new_arrival/image2.png",
                    alt: "Chocolate Ice Cream",
                  },
                  {
                    src: "media/new_arrival/image3.png",
                    alt: "Plain Ice Cream",
                  },
                  {
                    src: "media/new_arrival/image4.png",
                    alt: "Mango Ice Cream",
                  },
                ].map((item, index) => (
                  <div className="image-wrapper" key={index}>
                    <motion.img
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      transition={{
                        duration: 0.8 + index * 0.2,
                        type: "spring",
                        stiffness: 200,
                        damping: 10,
                      }}
                      variants={{
                        visible: { opacity: 1, scale: 1, y: 0 },
                        hidden: { opacity: 0, scale: 0.5, y: 100 },
                      }}
                      whileHover={{
                        scale: 1.1,
                      }}
                      style={{
                        marginTop:
                          (screenSize > 821 && index == 1) ||
                          (screenSize > 821 && index == 3)
                            ? "11rem"
                            : "",
                      }}
                      whileTap={{ scale: 1.1 }} // Slight zoom on tap
                      src={toAbsoluteUrl(item.src)}
                      alt={item.alt}
                      className="color-toggle-image image-with-overlay"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="new_arrival_carousel d-none">
              <div className="mt-5  d-flex justify-content-center">
                <Carousel images={newArrivalImages} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="arrival_below">
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          whileHover="hover"
          className="text-center new_arrival_title"
        >
          Delicious Discovery
        </motion.p>
        <div className="text-center">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.8 + 0.2,
              type: "spring",
              stiffness: 200,
              damping: 10,
            }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.5, y: 100 },
            }}
            className="text-image"
            src={toAbsoluteUrl("media/new_arrival/divider_7 3.png")}
            alt="images"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <div {...handlers} className="category_container category_slider">
          <div className="category_carousel">
            <AnimatePresence initial={false} custom={direction}>
              {currentImages.map((item, index) => {
                const isSelected = selectedCategory === item.id;
                return (
                  <motion.div
                    custom={index}
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    key={item.title}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    className={`text-center ${isSelected ? "selected" : ""}`}
                    onClick={() => {
                      setSelectedCategory(item.id);
                      handleImageClick(item.images, "#e32024");
                    }}
                    style={{
                      borderRadius: "8px",
                      width: "14rem",
                      paddingTop: "1rem",
                    }}
                  >
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        custom={index}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                        dangerouslySetInnerHTML={{ __html: svgCode }}
                      ></motion.div>
                    ) : (
                      <img
                        src={item.images}
                        alt="category"
                        className="category_image"
                        category_image
                        style={{
                          width: screenSize < 425 ? "2.8rem" : "",
                          height: screenSize < 425 ? "2.8rem" : "",
                        }}
                      />
                    )}
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                        style={{
                          color: "#e32024",
                          fontSize: screenSize < 425 ? "18px" : "",
                        }}
                        className="category_title"
                      >
                        {item.title}
                      </motion.div>
                    ) : (
                      <div
                        className="category_title"
                        style={{
                          fontSize: screenSize < 425 ? "18px" : "",
                        }}
                      >
                        {item.title}
                      </div>
                    )}
                  </motion.div>
                );
              })}
            </AnimatePresence>
          </div>
        </div>

        <div className="category_slider_buttons category_slider">
          <button
            className="category_slider_button next_button_category"
            onClick={nextSlide}
          >
            <FontAwesomeIcon
              icon={faGreaterThan}
              style={{
                transform: "rotate(180deg)", // Rotates the icon 180 degrees
                fontSize: "12px", // Adjust the size to make it smaller
              }}
            />
          </button>
          <button
            className="category_slider_button prev_button_category"
            onClick={prevSlide}
          >
            <FontAwesomeIcon icon={faGreaterThan}></FontAwesomeIcon>
          </button>
        </div>

        <div className="delicious_images mt-5 ">
          <div className="tab_section d-flex justify-content-center gap-4 align-items-center ">
            {categories.map((item, index) => {
              const isSelected = selectedCategory === item.id;

              return (
                <AnimatePresence>
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    custom={index}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    variants={variants}
                    key={item.title}
                    className={`text-center ${
                      isSelected ? "selected" : ""
                    } cursor-pointer`}
                    onClick={() => {
                      if (!isSelected) {
                        setSelectedCategory(item.id);
                        handleImageClick(item.images, "#e32024");
                        item.images = "";
                      }
                    }}
                    style={{
                      borderRadius: "8px",
                      width: "14rem",
                      paddingTop: "1rem",
                    }}
                  >
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        custom={index}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                        dangerouslySetInnerHTML={{ __html: svgCode }}
                      ></motion.div>
                    ) : (
                      <img
                        src={item.images}
                        alt="category"
                        className="category_image"
                      />
                    )}
                    {isSelected & isCodeVisible ? (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={variants3}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        style={{
                          color: "#e32024",
                        }}
                        className="category_title"
                      >
                        {item.title}
                      </motion.div>
                    ) : (
                      <div className="category_title">{item.title}</div>
                    )}
                  </motion.div>
                </AnimatePresence>
              );
            })}
          </div>
        </div>

        <div className="mt-5 position-relative side_bar_section">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants2}
            className="position-relative"
          >
            <div className="homeDeliciousImagesMain">
              {selectedCategoryImage && selectedCategoryImage.length > 0
                ? selectedCategoryImage.map((item, index) => {
                    return (
                      <AnimatePresence>
                        <motion.img
                          initial="hidden"
                          whileInView="visible"
                          viewport={{ once: true }}
                          custom={index}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          transition={{
                            type: "spring",
                            stiffness: 400,
                            damping: 10,
                          }}
                          variants={variants}
                          key={selectedCategory}
                          className="homeDeliciousImages"
                          src={item.image}
                        />
                      </AnimatePresence>
                    );
                  })
                : ""}
            </div>
          </motion.div>

          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants4}
            className="side_bars "
          >
            {selectedSubCategories != 0 && selectedSubCategories.length > 0 ? (
              selectedSubCategories.map((item, index) => (
                <div className="side_bar_inner" key={index}>
                  <motion.div
                    key={item.title}
                    custom={index}
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${toAbsoluteUrl(
                          "media/arrival_section_below/subcategorybackground.png"
                        )})`,
                      }}
                      className="side_bar_background"
                    >
                      <div className="sidebar_subcategory_name">
                        {item.title == "View More" ? (
                          <>
                            <li>
                            
                              <Link  style={{color:"#6b3d2b"}} to={item.path} state={{categoryId:item.categoryId}}  className="text-decoration-none" >{item.title}</Link>
                            </li>
                          </>
                        ) : (
                          <li>{item.title}</li>
                        )}
                      </div>
                    </div>
                  </motion.div>
                </div>
              ))
            ) : (
              <div className="side_bar_inner">
                <motion.div
                  key={selectedCategory}
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      "media/arrival_section_below/subcategorybackground.png"
                    )})`,
                  }}
                  className="side_bar_background"
                >
                  <div className="sidebar_subcategory_name">
                    <li>No subcategories</li>
                  </div>
                </motion.div>
              </div>
            )}
          </motion.div>
        </div>
      </div>

      <div className="search_section">
        <div className="d-flex  justify-content-between align-items-center">
          <div className="d-flex justify-content-start">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={leftSide}
              src={toAbsoluteUrl("media/new_arrival/sidelineleft.png")}
              alt="category"
              className="side_lines"
            />
          </div>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={animationVariants}
            className="text-center new_arrival_title pt-5"
          >
            Rangoli Near You
          </motion.p>
          <div className="d-flex justify-content-end">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={rightSide}
              src={toAbsoluteUrl("media/new_arrival/sidelineright.png")}
              alt="category"
              className="side_lines"
            />
          </div>
        </div>
        <div className="text-center">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.8 + 0.2,
              type: "spring",
              stiffness: 200,
              damping: 10,
            }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.5, y: 100 },
            }}
            className="text-image"
            src={toAbsoluteUrl("media/new_arrival/divider_7 3.png")}
            alt="images"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <div className="container">
          <div className="row search-select">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants2}
              whileTap={{ scale: 0.9 }}
              className="col-lg-6 col-md-6 col-sm-12 search_select_first"
            >
              <Select
                name="vendor"
                id="vendorId"
                placeholder="Select State"
                isSearchable={true}
                styles={customStyles} // Apply custom styles
              />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants4}
              whileTap={{ scale: 0.9 }}
              className="col-lg-6 col-md-6 col-sm-12"
            >
              <Select
                name="vendor"
                id="vendorId"
                placeholder="Select City"
                isSearchable={true}
                styles={customStyles} // Apply custom styles
              />
            </motion.div>
          </div>
        </div>
        <div className="map-section">
          <div className=" d-flex justify-content-center ">
            <motion.div className="map">
              <GoogleMapComponent
                latitude={latitude}
                longitude={longitude}
                height={"288px"}
              />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="journey_section">
        <motion.p
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={animationVariants}
          className="text-center new_arrival_title"
        >
          Our joyride Over The Years
        </motion.p>
        <div className="text-center">
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{
              duration: 0.8 + 0.2,
              type: "spring",
              stiffness: 200,
              damping: 10,
            }}
            variants={{
              visible: { opacity: 1, scale: 1, y: 0 },
              hidden: { opacity: 0, scale: 0.5, y: 100 },
            }}
            className="text-image"
            src={toAbsoluteUrl("media/new_arrival/divider_7 3.png")}
            alt="images"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>

        <div className="d-flex justify-content-evenly journey-three p-5">
          
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={variants5}
          >
            <img
              src={toAbsoluteUrl("media/home_banner/ourjourneyimage.jpg")}
              alt="image1"
              className="journeyimage2 w-100"
            />
          </motion.div>
         
        </div>
        <div className="journey-carousel mt-5 d-none">
          <div className="d-flex justify-content-evenly flex-wrap">
            <div className="d-flex justify-content-center">
              <Carousel images={journeyImages} />
            </div>
          </div>
        </div>
      </div>

      <div className="connect_section p-1">
        <div>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={animationVariants}
            whileHover="hover"
            className="text-center new_arrival_title"
          >
            Connect With Us
          </motion.p>
          <div className="text-center">
            <motion.img
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{
                duration: 0.8 + 0.2,
                type: "spring",
                stiffness: 200,
                damping: 10,
              }}
              variants={{
                visible: { opacity: 1, scale: 1, y: 0 },
                hidden: { opacity: 0, scale: 0.5, y: 100 },
              }}
              className="text-image"
              src={toAbsoluteUrl("media/new_arrival/divider_7 3.png")}
              alt="images"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>

          <div className="instagram_section d-flex justify-content-center align-items-center  mt-2">
            <Link
              to={"https://www.instagram.com/rangoli.icecream/"}
              target="_blank"
              className="text-decoration-none  d-flex justify-content-center align-items-center "
            >
              <div className="d-flex justify-content-center align-items-center">
                <motion.img
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={variants2}
                  whileTap={{ scale: 0.9 }}
                  className="instagram-image cursor-pointer"
                  src={toAbsoluteUrl("media/connectwithus/ins.png")}
                  alt="instagram logo"
                />
              </div>

              <div className="d-flex justify-content-center align-items-center">
                <motion.p
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={animationVariants}
                  whileHover="hover"
                  className="text-center instagram_title cursor-pointer"
                >
                  RANGOLIICECREAM.OFFICIAL
                </motion.p>
              </div>
            </Link>
          </div>
        </div>

        <Slider {...settings} className="connect-image  mt-5 ">
          <motion.div
            whileHover={{ scale: 0.9 }}
            className="cursor-pointer d-flex justify-content-center"
          >
            <img
              src={toAbsoluteUrl("media/connectwithus/image1.png")}
              alt="image1"
            />
          </motion.div>
          <motion.div
            whileHover={{ scale: 0.9 }}
            className="cursor-pointer d-flex justify-content-center"
          >
            <img
              src={toAbsoluteUrl("media/connectwithus/image2.png")}
              alt="image2"
            />
          </motion.div>
          <motion.div
            whileHover={{ scale: 0.9 }}
            className="cursor-pointer d-flex justify-content-center"
          >
            <img
              src={toAbsoluteUrl("media/connectwithus/image3.png")}
              alt="image3"
            />
          </motion.div>
          <motion.div
            whileHover={{ scale: 0.9 }}
            className="cursor-pointer d-flex justify-content-center"
          >
            <img
              src={toAbsoluteUrl("media/connectwithus/image4.png")}
              alt="image4"
            />
          </motion.div>
          <motion.div
            whileHover={{ scale: 0.9 }}
            className="cursor-pointer d-flex justify-content-center"
          >
            <img
              src={toAbsoluteUrl("media/connectwithus/image5.png")}
              alt="image4"
            />
          </motion.div>
        </Slider>
      </div>
      <Footer />
    </>
  );
};

const Home = () => {
  return <Header element={<HomeWrapper />} />;
};

export { Home };
