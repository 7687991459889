import React, { useEffect, useRef, useState } from "react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { toAbsoluteUrl } from "../helpers/AssetHelpers";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import ImageRain from "./components/ImageRain";
import AnimatedText from "./components/Animatetext";

const ErrorWrapper = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `../../`;
    navigate(path);
  };
  const variants2 = {
    hidden: { opacity: 0, x: -200 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };

  const variants4 = {
    hidden: { opacity: 0, x: 300 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      x: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const variants5 = {
    hidden: { opacity: 0, y: 300 }, // Start off-screen to the left
    visible: (i) => ({
      opacity: 1,
      y: 0, // Move to the original position
      transition: {
        delay: i * 0.2, // Adds a staggered delay
        type: "spring", // Use spring animation for smoothness
        stiffness: 50, // Controls how bouncy the animation is
        damping: 20, // Controls the smoothness
      },
    }),
  };
  const imageUrls = [
    `${toAbsoluteUrl("media/home_banner/icecream_banner.svg")}`,
    `${toAbsoluteUrl("media/home_banner/sundae_banner.svg")}`,
    `${toAbsoluteUrl("media/home_banner/shake_banner.svg")}`,
    `${toAbsoluteUrl("media/rangoli_logo.svg")}`,

    // Add more image URLs as needed
  ];
  return (
    <div >
      <div className="app-container">
        {/* Render the ImageRain component */}
        <ImageRain imageUrls={imageUrls} dropCount={100} />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <motion.img
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          data-aos="flip-left"
          className="cursor-pointer "
          onClick={routeChange}
          src={toAbsoluteUrl("media/rangoli_logo.svg")}
          alt="Logo"
        />
      </div>
      <div className="text-center" style={{paddingBottom :"1rem"}}>
        <div className="layered_text2 container">
          <div className="row text-center">
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants4}
              whileTap={{ scale: 0.9 }}
              className="text1"
            >
              Page Not Found
            </motion.p>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants5}
              whileTap={{ scale: 0.9 }}
              className="text2"
            >
           Page Not Found
            </motion.p>
            <motion.p
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={variants2}
              whileTap={{ scale: 0.9 }}
              className="text3"
            >
           Page Not Found
            </motion.p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const ErrorPage = () => {
  return <Header element={<ErrorWrapper />} />;
};

export { ErrorPage };
